import React from "react";
import IconBase from "./IconBase";
import icon from "./info.svg";

export default function IconInfo({ width, height }) {
  return (
    <IconBase
      alt="mehr Informationen"
      img={icon}
      width={width}
      height={height}
    />
  );
}
