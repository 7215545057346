import React, { createContext, useState } from "react";

export const ImageModalContext = createContext();

const ImageModalContextProvider = ({ children }) => {
  const [imageModal, setImageModal] = useState({
    visible: false,
    imageUrl: false,
    link: false,
    asset: {},
  });

  return (
    <ImageModalContext.Provider value={[imageModal, setImageModal]}>
      {children}
    </ImageModalContext.Provider>
  );
};

export default ImageModalContextProvider;
