import React from "react";
import * as styles from "./ImageModal.module.scss";
import IconInfo from "./icons/IconInfo";
import { navigate } from "gatsby-link";
import { CursorContext } from "../context/CursorContext";
import { ImageModalContext } from "../context/ImageModalContext";
import SanityImage from "gatsby-plugin-sanity-image";
import { GatsbyImage } from "gatsby-plugin-image";

export default function ImageModal() {
  const [, setCursor] = React.useContext(CursorContext);
  const [imageModal, setImageModal] = React.useContext(ImageModalContext);

  const toggleCursor = React.useCallback((enabled) => {
    setCursor(() => ({ hover: enabled }));
  });

  const toggleCursorClose = React.useCallback((enabled) => {
    setCursor(() => ({ close: enabled }));
  });

  function navBack() {
    toggleCursor(false);
    toggleCursorClose(false);
    setImageModal(() => ({ open: false, link: null, asset: {} }));
  }

  if (!imageModal.visible) {
    return null;
  } else {
    return (
      <div
        className={`${styles.ModalContainer} ${
          !imageModal.link ? styles.DarkBackground : null
        }`}
        onClick={navBack}
      >
        <div
          className={styles.ModalOne}
          onMouseEnter={() => toggleCursorClose(false)}
          onMouseLeave={() => toggleCursorClose(true)}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <GatsbyImage
            onMouseEnter={() => toggleCursorClose(false)}
            onMouseLeave={() => toggleCursorClose(true)}
            image={imageModal.asset.gatsbyImageData}
            imgStyle={{ objectFit: "contain" }}
            className={styles.ImgContainer}
            alt={`Bildvorschau`}
          />
          {imageModal.link ? (
            <div
              className={styles.ModalMoreButton}
              onMouseEnter={() => toggleCursor(true)}
              onMouseLeave={() => toggleCursor(false)}
              onClick={(e) => {
                e.stopPropagation();
                navBack();
                navigate(imageModal.link);
              }}
            >
              <IconInfo />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
