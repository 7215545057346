import "@fontsource/cormorant/500.css";
import "@fontsource/nunito-sans/900.css";
import "@fontsource/nunito-sans/800.css";
import "@fontsource/reem-kufi/700.css";
import "@fontsource/cormorant-garamond";

import "./src/styles/global.scss";
import * as React from "react";
import { AnimatePresence } from "framer-motion";

import CursorContextProvider from "./src/context/CursorContext";
import Cursor from "./src/components/Cursor";
import ImageModalContextProvider from "./src/context/ImageModalContext";
import ImageModal from "./src/components/ImageModal";

export const wrapRootElement = ({ element }) => (
  <CursorContextProvider>
    <Cursor />
    <ImageModalContextProvider>
      <ImageModal />
      {element}
    </ImageModalContextProvider>
  </CursorContextProvider>
);

export function wrapPageElement({ element, props }) {
  const Layout = element.type.Layout ?? React.Fragment;
  return (
    <AnimatePresence exitBeforeEnter>
      <Layout {...props}>{element}</Layout>
    </AnimatePresence>
  );
}
