import React from "react";
import { CursorContext } from "../context/CursorContext";
import useMousePosition from "./../hooks/useMousePosition";
import IconClose from "./icons/IconClose";
import * as styles from "./Cursor.module.scss";
import IconForward from "./icons/IconForward";
import IconBack from "./icons/IconBack";

const Cursor = () => {
  const { clientX, clientY } = useMousePosition();
  const [cursor] = React.useContext(CursorContext);
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    if (
      "ontouchstart" in window ||
      navigator.MaxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    ) {
      setIsVisible(false);
    } else {
      const handleMouseEnter = () => setIsVisible(true);
      const handleMouseLeave = () => setIsVisible(false);
      document.body.addEventListener("mouseenter", handleMouseEnter);
      document.body.addEventListener("mouseleave", handleMouseLeave);
      return () => {
        document.body.removeEventListener("mouseenter", handleMouseEnter);
        document.body.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  }, []);

  if (!isVisible) {
    return null;
  } else if (cursor.close) {
    return (
      <div
        id="cursor"
        className={`${styles.cursorIcon} ${
          cursor.hover ? styles.cursorHover : null
        }`}
        style={{
          left: clientX,
          top: clientY,
        }}
      >
        <IconClose width="32px" height="32px" />
      </div>
    );
  } else if (cursor.forward) {
    return (
      <div
        id="cursor"
        className={`${styles.cursorIcon} ${
          cursor.hover ? styles.cursorHover : null
        }`}
        style={{
          left: clientX,
          top: clientY,
        }}
      >
        <IconForward width="32px" height="32px" />
      </div>
    );
  } else if (cursor.backward) {
    return (
      <div
        id="cursor"
        className={`${styles.cursorIcon} ${
          cursor.hover ? styles.cursorHover : null
        }`}
        style={{
          left: clientX,
          top: clientY,
        }}
      >
        <IconBack width="32px" height="32px" />
      </div>
    );
  } else {
    return (
      <div
        id="cursor"
        className={`${styles.cursor} ${
          cursor.hover ? styles.cursorHover : null
        }`}
        style={{
          left: clientX,
          top: clientY,
        }}
      />
    );
  }
};

export default Cursor;
