import React from "react";

export default function IconBase({ img, width, height, alt }) {
  return (
    <img
      alt={alt ? alt : "Icon"}
      width={width ? width : "48px"}
      height={height ? height : "48px"}
      src={img}
    />
  );
}
